
@font-face {
  font-family: 'opoce360';
  src: url('../resources/fonts/icons/opoce360.woff2') format('woff2'), /* Super Modern Browsers */
  url('../resources/fonts/icons/opoce360.woff') format('woff'), /* Pretty Modern Browsers */
  url('../resources/fonts/icons/opoce360.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../resources/fonts/icons/opoce360.svg') format('svg');
}

#quiz {
  .opoce-icon{
    &:before,&:after {
      font-family: 'opoce360';
      speak: none;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
    }
  }
  .icon-link:after {
    content: "\e900"
  }
  .icon-link:before{
    display:none;
  }
  .icon-right:after {
    content: "\e903";
  }
  .icon-close:before {
    content: "\e901";
    color: #f5115d;
  }
  .icon-checkmark:before {
    content: "\e902";
    color:$green-quiz;
  }
}