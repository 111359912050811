$feedback-height:100px;

#quiz{
  .feedback{
    height: $feedback-height;
    width:100%;
    position:relative;
    box-shadow: 0 4px 11px rgba(0, 0, 0, 0.18);
    line-height: $feedback-height;
    @media screen and(max-width:$small-break){
      line-height:75px;
      height:75px;
    }
    > a{
      height:100%;
    }
    &__img{
      float:right;
      height:100%;
      width:auto;
      margin-right:10px;
      @media screen and(max-width:$small-break){
        height:70px;
      }
    }
    &__number,&__position{
      display:inline-block;
    }
    &__number{
      font-size:1.2vw;
      @media screen and (max-width:$small-break) {
        font-size:14px;
      }
    }
    &__position{
      float:right;
      font-size:11px;
      li{
        display:inline-block;
        margin-right:30px;
        span{
          position:relative;
          &:before{
            content:"";
            width:34px;
            height:5px;
            position:absolute;
            top:50%;
            margin-top:15px;

            left:-11px;
            background-color:$grey-quiz;
          }
          &.is-current{
            &:before{
              background-color:$yellow-quiz;
            }
          }
          &.is-correct{
            &:before{
              background-color:$green-quiz;
            }
          }
          &.is-false{
            &:before{
              background-color:$red-quiz;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width:$large-break){
  #quiz{
    .feedback {
      padding:0 10px;
      &__position{
        display:none;
      }
    }
  }
}