/*****quiz start and finish
**********************************************/
#quiz{
  .questions{
    @media screen and(min-width:$large-break){
      min-height:600px;
    }
    h3{
      font-weight:bold;
      font-size:2vw;
      margin:3vw 0;
      > span{
        display:block;
      }
    }
    &__start{
      padding-top:40px;
      text-align: center;
      &.is-started{
        display:none;
      }
    }
    &__end{
      text-align: center;
      button{
        margin-top:50px;
      }
    }
  }
}

@media screen and (max-width:$large-break) {
  #quiz {
    .questions {
      margin:0 10px;
      h3 {
        font-weight: bold;
        font-size: 30px;
      }
    }
  }
}

@media screen and (max-width:$small-break) {
  #quiz {
    .questions {
      h3 {
        font-size: 22px;
      }
    }
  }
}

.quiz-bar{
  &__overall{
    width:100%;
    margin:0 auto;
    height:20px;
    background-color:rgba($yellow-quiz,0.2);
    position:relative;
  }
  &__actual{
    position:absolute;
    top:0;
    width:0;
    left:0;
    bottom:0;
    background-color:$yellow-quiz;
  }
  &__percent{
    position:absolute;
    right:0;
    top:100%;
    font-size:30px;
    font-weight:bold;
    margin-top:10px;
    display:inline-block;
    transform:translatex(50%);
  }
}




/*****quiz info
**********************************************/

#quiz {
  .questions {
    &__info{
      li{
        display:none;
        &.is-active{
          display:block;
        }
      }
    }
    &__answer {
      > button {
        text-align: left;
        width: 500px;
        height: auto;
        min-height:70px;
        background: transparent;
        border: 1px solid $grey-quiz;
        padding-left: 15px;
        font-size: 1.2vw;
        line-height:1.3;
        &.correct-answer {
          background-color: $green-quiz;
          color: white;
        }
        &.false-answer {
          background-color: $red-quiz;
          color: white;
        }
        &.corrected-answer {
          border: 2px solid $green-quiz;
        }
        &:first-child {
          border-radius: 3px 3px 0 0;
        }
        &:last-child {
          border-radius: 0 0 3px 3px;
        }
      }
    }
  }
}



/*****quiz feedback
**********************************************/


#quiz{
  .questions {
    .is-right-answer,.is-wrong-answer{
      &.questions__feedback{
        display:block;
      }
    }
    &__next{
      color:white;
      width:auto;
      background-color:$blue-dark-quiz;
      padding:20px;
      overflow: hidden;
      &-button{
        padding:20px;
        border:2px solid white;
        color:white;
        font-size:0.9vw;
        font-weight:bold;
        background:none;
        float:right;
        @media screen and (max-width:$large-break) {
          padding:10px;
          margin-top:20px;
          font-size:13px;
        }
        &:after{
          color:white;
          font-size:21px;
          margin-left:10px;
          vertical-align: middle;
        }
      }
      span{
        font-size:15px;
        font-weight:bold;
        vertical-align: middle;
        display:none;
        float:left;
        max-width:60%;
        margin-top:15px;
        &:before{
          margin-right:15px;
          vertical-align: middle;
          font-size:33px;
          margin-bottom:4px;
        }
      }
    }
    &__feedback-info{
      p{
        font-size:15px;
      }
    }
    .is-right-answer{
      .question__next-info--correct{
        display:block;
      }
      .questions__feedback-info{
        display:block;
      }
    }
    .is-wrong-answer{
      .question__next-info--false{
        display:block;
      }
      .questions__feedback-info{
        display:block;
      }
    }
    &__feedback{
      box-shadow: 0 4px 26px rgba(0, 0, 0, 0.13);
      margin-top:20px;
      display: none;
      &-info{
        display:none;
        padding:20px;
        a{
          color:$blue-quiz;
          text-decoration:none;
        }
      }
    }
  }
}

.results-button{
  padding:20px;
  border:2px solid white;
  color:white;
  font-size:16px;
  font-weight:bold;
  background:none;
  float:right;
}

@media screen and (max-width:$large-break) {
  #quiz .questions__answer > button{
    width:100%;
    font-size:17px;
  }
  #quiz .questions__next span{
    width:100%;
    max-width:none;
  }
}