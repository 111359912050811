.button-standard{
  padding:20px;
  color:white;
  max-width:220px;
  width:auto;
  font-size:1.1vw;
  font-weight:bold;
  background:$blue-dark-quiz;;
  border:none;
  @media screen and(max-width:$medium-break){
    font-size:14px;
  }
}


/*js utility*/

.is-unclickable{
  > button {
    pointer-events: none;
  }
}