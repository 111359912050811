/***************************************************
****************************************************
COLORS
****************************************************
***************************************************/

/************************************************
background colors
************************************************/
/*grey*/

$grey-quiz:#d3d3d3;

/*green*/
$green-quiz:#27AE60;

/*yellow*/
$yellow-quiz:#FFCB05;

/*blue*/
$blue-quiz:#20A5DE;
$blue-dark-quiz:#0057A0;

/*black*/
$black-quiz:#00213F;

/*red*/
$red-quiz:#A0004D;

/************************************************
font-colors
************************************************/


/***************************************************
****************************************************
BREAKS
****************************************************
***************************************************/

$small-break:640px;

$medium-break:767px;

$large-break:1023px;




