
#quiz ul{
  margin:0;
  list-style-type: none;
  padding:0;
}

#quiz *{
  box-sizing: border-box;
}


#quiz{
  h2,h3{
    font-weight: 400;
  }
}

#quiz{
  figure,p{
    margin:0;
  }
}



#quiz a{
  color:inherit;
}

#quiz img{
  max-width:100%;
}

#quiz button{
  cursor:pointer;
}

#quiz{
  img, button,figure{
    &:focus{
      outline:none;
    }
  }
}


#quiz{
  margin-bottom:10vh;
}



